@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: #e55a28;
    border-color: var(--tw-ring-color);
  }

  [type="checkbox"],
  [type="radio"] {
    color: #e55a28;
  }

  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-color: #e55a28;
  }

  button:focus,
  a:focus {
    box-shadow: 0 0 0 3px rgba(229, 90, 40, 0.5);
    border-color: #e55a28;
    outline: none;
  }
}
